import React from 'react';
import {faTwitter, faDiscord} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const SplashPage = () => {
    return (
        <div className="splash-heading">
        <h1><span className="Highlight Bold">livestream</span>.tools</h1>
        <p>Easy to use tools that improve your streams. <span className="Highlight Bold">Built with love, not with profit.</span></p>
    </div>
    )
}

export const Socials = () => {
    return (
        <div className="social-links">
        <ul>
        <a href="https://twitter.com/livestreamtools" target="_blank" rel="noreferrer" className="main-links"><li><span className="icon"><FontAwesomeIcon icon={faTwitter} fixedWidth/></span>Twitter</li></a>
        <a href="https://addz.xyz/discord" target="_blank" rel="noreferrer"  className="main-links"><li><span className="icon"><FontAwesomeIcon icon={faDiscord} fixedWidth/></span>Discord</li></a>
        </ul>
        </div>
    )
}