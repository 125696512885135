import './App.css';
import {SplashPage} from './components/splash';
import { Socials } from './components/splash';

function App() {
  return (
    <div className="home">
    <SplashPage />
    <Socials />
    </div>
  );
}

export default App;
